<template>
  <div class="home-wrapper">
    <div class="head-wrapper">
     <ul>
        <li></li>
        <li></li>
      </ul>
    </div>
    <div class="home-con">
      <ul class="link-wrapper">
        <li>
          <div class="link">
            <div class="inner">
              <span class="address">www.xxxxx.com</span>
              <span class="speed">172ms</span>
            </div>
          </div>
          <span class="btn">Official Website</span>
        </li>
        <li>
          <div class="link">
            <div class="inner">
              <span class="address">www.xxxxx.com</span>
              <span class="speed">172ms</span>
            </div>
          </div>
          <span class="btn">Official Website</span>
        </li>
        <li>
          <div class="link">
            <div class="inner">
              <span class="address">www.xxxxx.com</span>
              <span class="speed">172ms</span>
            </div>
          </div>
          <span class="btn">Official Website</span>
        </li>
      </ul>
      <ul class="group-wrapper">
        <li>
          <img src="./assets/img/icon1.png" alt="">
          <p>depositos mais rapidos</p>
        </li>
        <li>
          <img src="./assets/img/icon2.png" alt="">
          <p>garantia de fundos</p>
        </li>
        <li>
          <img src="./assets/img/icon3.png" alt="">
          <p>compartilhe e ganhe dinheiro</p>
        </li>
      </ul>

      <div class="game-wrapper">
        <h2 class="mod-title">
          <img src="./assets/img/icon_title.png" alt="">
          <span>marca de cooperação</span>
        </h2>
        <ul class="game-con">
          <li>
            <img src="./assets/img/game1.png" alt="">
          </li>
          <li>
            <img src="./assets/img/game2.png" alt="">
          </li>
          <li>
            <img src="./assets/img/game3.png" alt="">
          </li>
          <li>
            <img src="./assets/img/game4.png" alt="">
          </li>
          <li>
            <img src="./assets/img/game5.png" alt="">
          </li>
          <li>
            <img src="./assets/img/game6.png" alt="">
          </li>

        </ul>
      </div>
      <div class="banner-wrapper">
        <h2 class="mod-title">
          <img src="./assets/img/icon_title.png" alt="">
          <span>melhores jogos</span>
        </h2>
        <div class="swiper-con">
          <swiper
                  :modules="modules"
                  :slides-per-view="3"
                  :space-between="10"
                  :pagination="{ clickable: true }"
                  @swiper="onSwiper"
                  @slideChange="onSlideChange"
          >
            <swiper-slide v-for="(item,index) in imgList" :key="index">
              <img :src="item" alt="">
            </swiper-slide>
          </swiper>
        </div>
      </div>

      <div class="btn-wrapper">
        <span>立即下载</span>
      </div>
    </div>

  </div>
</template>

<script>
  import { Pagination} from 'swiper';
  import { Swiper, SwiperSlide } from 'swiper/vue';

  import 'swiper/css';
  import 'swiper/css/pagination';
  export default {
    setup() {
      const onSwiper = (swiper) => {
        console.log(swiper);
      };
      const onSlideChange = () => {
        console.log('slide change');
      };
      return {
        onSwiper,
        onSlideChange,
        modules: [Pagination],
      };
    },
    data(){
      return{
        imgList:[
          require('./assets/img/banner1.png'),
          require('./assets/img/banner2.png'),
          require('./assets/img/banner3.png'),
          require('./assets/img/banner2.png'),
          require('./assets/img/banner1.png'),
        ],
      }
    },
    components: {
      Swiper,
      SwiperSlide,
    },

  };
</script>


<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.home-wrapper {
  width: 100%;
  height: 100%;
  background: url("./assets/img/bg.png") top center;
  background-size: 100% 100%;
  overflow-x: hidden;

  .home-con{
    padding:0 0.2rem;
  }

  .head-wrapper {
    width: 100%;
    height: 8rem;
    background: url("./assets/img/banner.png") top center no-repeat;
    background-size: 100% auto;

    ul {
      height: 1rem;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0 0.2rem;

      li {
        height: 100%;

        &:first-child {
          width: 50%;
          background: url("./assets/img/logo.png") left center no-repeat;
          background-size: auto 0.6rem;
        }

        &:last-child {
          width: 50%;
          background: url("./assets/img/service.png") right center no-repeat;
          background-size: auto 0.6rem;
        }
      }
    }
  }


  .mod-title{
    display: flex;
    align-items: center;
    img{
      width:0.28rem;
      height:0.28rem;
      margin-right: 0.12rem;
    }
    span{
      font-size: 0.36rem;
      line-height: 0.4rem;
      color:#fff;
    }

  }

  ul.link-wrapper {
    width: 100%;

    li {
      width: 100%;
      display: flex;
      justify-content:space-between;
      &:not(:last-child){
        margin-bottom: 0.2rem;
      }

      .link {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.02rem;
        width: 4.6rem;
        height: 0.8rem;
        border-radius: 0.1rem;
        margin-right: 0.2rem;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        linear-gradient(180deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.25) 100%);
        border: 1px solid;
        border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.25) 100%);

        .inner{
          width: 100%;
          height: 100%;
          background: #000;
          border-radius: 0.1rem;
          font-size:0.28rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding:0 0.2rem;

          .address {
            color:#fff;
          }
          .speed{
            color:#38CF90;
          }
        }
      }

    }
    .btn{
      flex:1;
      /*width:2.3rem;*/
      height: 0.8rem;
      padding: 0 0.1rem;
      border-radius: 0.1rem;
      color:#fff;
      font-size:0.28rem;
      background: #0790f2;
      display: flex;
      align-items: center;
      justify-content: center;


    }
  }

  ul.group-wrapper{
    display: flex;
    margin: 0.4rem 0 0.6rem;
    li{
      width:calc(100% / 3);
      padding:0 0.2rem;
      img{
        width:1.2rem;
        height:1.2rem;
      }
      p{
        font-size: 0.24rem;
        line-height:0.34rem;
        text-align: center;
        margin-top: 0.2rem;
        color:#fff;
      }
    }
  }

  .game-wrapper{
    ul.game-con{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap:0.2rem;
      grid-row-gap:0.22rem;
      margin-top: 0.2rem;
      li{
        width:2.22rem;
        height:0.8rem;
        img{
          width:100%;
        }
      }
    }
  }

  .banner-wrapper{
    margin-top: 0.6rem;
    .swiper-con{
      margin-top: 0.2rem;
      position: relative;
      .swiper{
        position: initial;
      }
      .swiper-pagination{
        height: 0.3rem;
        bottom: -0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .swiper-pagination-bullet{
          width:0.16rem;
          height:0.04rem;
          border-radius: 0;
          background: rgba(255, 255, 255, 0.5);
          opacity: 1;
          &.swiper-pagination-bullet-active{
            background: #fff;
          }
        }
      }
      img{
        width:100%;
      }
    }
  }



  .btn-wrapper{
    margin: 0.8rem 0 0.4rem;
    span{
      width:100%;
      height: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.26rem;
      background: #0790f2;
      border-radius: 0.1rem;
      color:#fff;
    }
  }
}

</style>
